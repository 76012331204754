import * as dompack from "dompack";
import "@mod-mso_templates/webdesigns/penitus/penitus";

import "./go_planet.scss";
//import "sweetalert2/dist/sweetalert2.min.css";

dompack.onDomReady(() => {
  refactorLinks();
});

function refactorLinks() {
  const isURLRegex = /^(http|https)\:\/\//;
  const origin = window.location.origin.replace(isURLRegex, "");
  const links = dompack.qSA("a");

  let link_counter = 0;

  for (const link of links) {
    const target = link.getAttribute("href");
    if (!target || !isURLRegex.test(target) || target.includes(origin)) {
      continue;
    }

    link_counter++;
    link.setAttribute("target", "_blank");
  }

  console.info("Added target=_blank to " + link_counter + " links.");
}
